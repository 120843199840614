/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import "@fontsource/karla/400.css"
import "@fontsource/karla/400-italic.css"
import "@fontsource/karla/500.css"
import "@fontsource/karla/500-italic.css"
import "@fontsource/karla/600.css"
import "@fontsource/karla/600-italic.css"
import "@fontsource/karla/700.css"
import "@fontsource/karla/700-italic.css"
import "@fontsource/karla/800.css"
import "@fontsource/karla/800-italic.css"

import "@fontsource/kanit"
import "@fontsource/kanit/500.css"
import "@fontsource/kanit/600.css"
import "@fontsource/kanit/700.css"
import "@fontsource/kanit/800.css"

import "./src/styles/global.css"
import "./src/styles/cookies.css"

import { screenshots } from "./src/components/utils/Screenshots"

const addAttrToBody = () => {
    document.querySelector('body').setAttribute('data-animated', 'true');
}

export const onInitialClientRender = () => {
    screenshots.homepageDesktop();
    screenshots.homepageMobile();
    addAttrToBody();
}


export const onRouteUpdate = ({ location, prevLocation }) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'gatsbyRouteChange',
        previousPage: prevLocation ? prevLocation.pathname + prevLocation.search : null,
        currentPage: location.pathname + location.search,
    });
};
