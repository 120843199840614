import { isBrowser, checkEdParams } from '@utils/Utils'

export const screenshots = {
    homepageDesktop: () => {
        const homepageDesktop = isBrowser && window.location.href.indexOf("homepageDesktop=1") > -1;

        if (homepageDesktop) {

            if (checkEdParams('animate=One')) {
                const elSections = document.querySelectorAll('.hp-sections:not(#sectionOne), footer, .sectionOne-textContent');
                elSections.forEach(el => el.style.display = 'none')
            }
            if (checkEdParams('animate=OneEnd')) {
                const elSections = document.querySelectorAll('.hp-sections:not(#sectionOne),footer');
                elSections.forEach(el => el.style.display = 'none')
                document.querySelector('#overlay').style.opacity = 0.8;
                document.querySelector('#sectionOne-bg-img').style.transform = 'translate3d(0px, -35.385px, 0px) scale(1.1351, 1.1351)';
                document.querySelector('#section-one-heading').style.transform = 'translate(0px, -83px)';
                document.querySelector('#sectionOne-textContent').style.transform = 'translate(0px, -888px)';
                document.querySelector('#sectionOne-textContent').style.display = 'grid';
            }


            if (checkEdParams('animate=Two')) {
                const elSections = document.querySelectorAll('.hp-sections:not(#sectionTwo),footer');
                elSections.forEach(el => el.style.display = 'none');
            }
            if (checkEdParams('animate=TwoBetween')) {
                const elSections = document.querySelectorAll('.hp-sections:not(#sectionTwo),footer');
                elSections.forEach(el => el.style.display = 'none');
                setTimeout(() => {
                    document.querySelector('#sectionTwo').style.transform = 'translateY(-600px)';
                }, 300)
            }
            if (checkEdParams('animate=TwoEnd')) {
                const elSections = document.querySelectorAll('.hp-sections:not(#sectionTwo),footer');
                elSections.forEach(el => el.style.display = 'none');
                setTimeout(() => {
                    document.querySelector('#sectionTwo').style.transform = 'translateY(-986px)';
                }, 300)
            }

            if (checkEdParams('animate=Three')) {
                const elSections = document.querySelectorAll('.hp-sections:not(#sectionThree,#sectionThreePartTwo),footer');
                elSections.forEach(el => el.style.display = 'none');
                document.querySelector('#animate-section-3').style.marginTop = '0';
                document.querySelector('#animate-section-3').style.borderRadius = '0';
                document.querySelector('div#sectionThree').style.transform = 'translateY(19px)';
            }

            if (checkEdParams('animate=Four') && !checkEdParams('animate=FourPartTwo') && !checkEdParams('animate=FourEnd')) {
                const elSections = document.querySelectorAll('.hp-sections:not(#sectionThree, #sectionThreePartTwo), .green-circle-lines-desktop, #safety-wrapper');
                elSections.forEach(el => el.style.display = 'none');
                document.querySelector('#animate-section-3').style.borderRadius = '0';
                document.querySelector('#animate-section-3').style.marginTop = '0';
                document.querySelector('#resources').style.transform = 'translate(0px, 316px)';

                document.querySelector('#anchor-two').style.transform = 'rotate(90deg) scale(0.54, 0.54)';
                document.querySelector('#anchor-two').style.top = '-20%';
                document.querySelector('#anchor-two').style.right = '-291px';
                setTimeout(() => {
                    document.querySelector('#sectionThreePartTwo .tan-background').style.transform = 'none';
                    document.querySelector('#sectionThreePartTwo .tan-background').style.transform = 'scale(2)';
                    document.querySelector('#sectionThreePartTwo [data-screenshot="section-three-part-two"]').style.overflow = 'visible';
                    document.querySelector('div#last-purple-helper-container div[data-id="circle"]').style.transform = 'translate3d(0px, -43px, 0px)';

                }, 500)
            }
            if (checkEdParams('animate=FourPartTwo') && !checkEdParams('animate=FourEnd')) {
                const elSections = document.querySelectorAll('.hp-sections:not(#sectionThree, #sectionThreePartTwo),footer, .green-circle-lines-desktop, #safety-wrapper');
                elSections.forEach(el => el.style.display = 'none');
                document.querySelector('#resources').style.transform = 'translate(0px, -49rem)';
                document.querySelector('.tan-background').style.transform = 'scale(2)';
                setTimeout(() => {
                    document.querySelector('#sectionThreePartTwo .tan-background').style.transform = 'scale(3,3)';
                }, 500)
            }

            if (checkEdParams('animate=FourEnd') && !checkEdParams('animate=FourEndTwo') && !checkEdParams('animate=FourEndThree')) {

                const elSections = document.querySelectorAll('.hp-sections:not(#sectionThree, #sectionThreePartTwo), .green-circle-lines-desktop, #safety-wrapper, #using-defencath, #anchor-one');
                elSections.forEach(el => el.style.display = 'none');

                document.querySelector('#resources').style.transform = 'translate(0px, -320px)';
                document.querySelector('#resources').style.maxWidth = 'none';
                document.querySelector('#animate-section-3').style.transform = 'translate3d(0px, -1252px, 0px)';
                document.querySelector('#animate-section-3').style.borderRadius = '40px';

                document.querySelector('#anchor-two').style.transform = 'rotate(90deg) scale(0.54, 0.54)';
                document.querySelector('#anchor-two').style.top = '-20%';
                document.querySelector('#anchor-two').style.right = '-291px';

                document.querySelector('.tan-background').style.transform = 'none';
                document.querySelector('div#sectionThree .gatsby-image-wrapper').style.display = 'none';
                document.querySelector('#sectionThreePartTwo .tan-background').style.transform = 'none';
                setTimeout(() => {
                    document.querySelector('#sectionThreePartTwo .tan-background').style.transform = 'none';
                    document.querySelector('#animate-section-2').style.minHeight = '0px';
                }, 600)
            }

            if (checkEdParams('animate=FourEndTwo')) {
                setInterval(() => {
                    window.scrollTo(0, 5700)
                }, 600)
            }
            if (checkEdParams('animate=FourEndThree')) {
                setInterval(() => {
                    window.scrollTo(0, 6500)
                }, 600)
            }
        }
    },
    homepageMobile: () => {

        const homepageMobile = isBrowser && window.location.href.indexOf("homepageMobile=1") > -1;

        if (homepageMobile) {

            if (checkEdParams('animate=One')) {
                const elSections = document.querySelectorAll('.hp-sections:not(#sectionOne), footer, .sectionOne-textContent');
                elSections.forEach(el => el.style.display = 'none')
            }
            if (checkEdParams('animate=OneEnd')) {
                const elSections = document.querySelectorAll('.hp-sections:not(#sectionOne),footer');
                elSections.forEach(el => el.style.display = 'none')
                document.querySelector('#overlay').style.opacity = 0.8;
                document.querySelector('#sectionOne-bg-img').style.transform = 'translate3d(0px, -186.724px, 0px) scale(1.7127, 1.7127)';
                document.querySelector('#sectionOne-textContent').parentElement.style.transform = 'translate(0px, 0px)';
                document.querySelector('#animate-section-3').style.display = 'hidden';
            }


            if (checkEdParams('animate=Two')) {
                const elSections = document.querySelectorAll('.hp-sections:not(#sectionTwo),footer, #how-defencath-works');
                elSections.forEach(el => el.style.display = 'none');
            }
            if (checkEdParams('animate=TwoEnd')) {
                const elSections = document.querySelectorAll('.hp-sections:not(#sectionTwo),footer, #unparalleled-efficacy, #animate-section-3, #hp-isi');
                elSections.forEach(el => el.style.display = 'none');
                document.querySelector('#how-defencath-works').style.display = 'block';

            }

            if (checkEdParams('animate=Three')) {
                const elSections = document.querySelectorAll('.hp-sections:not(#sectionThree),footer,[data-id="sticky-isi"],.last-purple-helper');
                elSections.forEach(el => el.style.display = 'none');
            }

            if (checkEdParams('animate=Four') && !checkEdParams('animate=FourEnd') && !checkEdParams('animate=FourEndTwo') && !checkEdParams('animate=FourEndThree') && !checkEdParams('animate=FourEndFour')) {
                const elSections = document.querySelectorAll('.hp-sections:not(#sectionThree, #sectionThreePartTwo),footer,[data-id="sticky-isi"],#safety-wrapper, .green-circle-lines-mobile');
                elSections.forEach(el => el.style.display = 'none');
                document.querySelector('#resources').style.transform = 'translate(0px, 111px)';
                document.querySelector('#anchor-two').style.transform = 'rotate(90deg)';
                document.querySelector('#anchor-two').style.top = '-190px';
                document.querySelector('#anchor-two').style.right = '0';
                document.querySelector('#anchor-two').style.width = '181px';
            }

            if (checkEdParams('animate=FourPartTwo')) {
                const elSections = document.querySelectorAll('.hp-sections:not(#sectionThree, #sectionThreePartTwo),footer,[data-id="sticky-isi"],#safety-wrapper, #anchor-two');
                elSections.forEach(el => el.style.display = 'none');
                document.querySelector('#resources').style.transform = 'translate(0px, -447px)';
            }

            if (checkEdParams('animate=FourEnd') && !checkEdParams('animate=FourEndTwo') && !checkEdParams('animate=FourEndThree') && !checkEdParams('animate=FourEndFour')) {
                const elSections = document.querySelectorAll('.hp-sections:not(#sectionThree, #sectionThreePartTwo),footer,[data-id="sticky-isi"], .green-circle-lines-desktop, #safety-wrapper, #using-defencath, [data-screenshot="header"]');
                elSections.forEach(el => el.style.display = 'none');

                document.querySelector('#anchor-two').style.transform = 'rotate(90deg)';
                document.querySelector('#anchor-two').style.top = '-190px';
                document.querySelector('#anchor-two').style.right = '0';
                document.querySelector('#anchor-two').style.width = '181px';

                setTimeout(() => {
                    document.querySelector('#resources').style.transform = 'translate(0px, -61px)';
                    document.querySelector('#homepage').style.marginTop = '-625px';
                }, 700)
            }

            if (checkEdParams('animate=FourEndTwo')) {
                setTimeout(() => {
                    window.scrollTo(0, 6500)
                    setInterval(() => {
                        window.scrollTo(0, 4650)
                    }, 1000)
                }, 2000)
            }

            if (checkEdParams('animate=FourEndThree')) {
                setTimeout(() => {
                    window.scrollTo(0, 5900)
                    setInterval(() => {
                        window.scrollTo(0, 4800)
                    }, 1000)
                }, 2000)
            }

            if (checkEdParams('animate=FourEndFour')) {
                setTimeout(() => {
                    document.querySelector('footer').scrollIntoView();
                    setInterval(() => {
                        document.querySelector('[data-screenshot="isi-right-content"]').scrollIntoView()
                    }, 1000)
                }, 2000)
            }
        }
    }

}
